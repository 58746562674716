  <app-navigation [drawer]="drawer" [disabled]="showWorkingPage"></app-navigation>
  
  <mat-sidenav-container class="app-container">
    <mat-sidenav-content>
      <app-worker-page *ngIf="showWorkingPage"></app-worker-page>
      <router-outlet *ngIf="!showWorkingPage"></router-outlet>
      <app-footer></app-footer>
    </mat-sidenav-content>
    
    <mat-sidenav #drawer class="link-container" mode="over" position="start">
      <div class="links">
        <a href="">Home</a>
        <a href="/over">Over ons</a>
        <a href="/contact">Contact</a>
      </div>
    </mat-sidenav>
  </mat-sidenav-container>
  