<app-page-base>
   <app-map></app-map>
</app-page-base>

<section class="text-center map-note">
   Klik op een gemeente op de kaart om gerelateerde artikels te zien.
</section>

<section class="container text-center">
   <h1>Doel van de vereniging</h1>
   <!-- <p>Ontsluiting en bewaring van de heemkundige gegevens van de 9 dorpen die het werkgebied van de vereniging omvatten: <a href="/gemeente/afsnee" class="url">Afsnee</a>, <a href="/gemeente/de-pinte" class="url">De Pinte</a>, <a href="/gemeente/deurle" class="url">Deurle</a>, <a href="/gemeente/eke" class="url">Eke</a>, <a href="/gemeente/nazareth" class="url">Nazareth</a>, <a href="/gemeente/sint-denijs-westrem" class="url">Sint-Denijs-Westrem</a>, <a href="/gemeente/sint-martens-latem" class="url">Sint-Martens-Latem</a>, <a href="/gemeente/zevergem" class="url">Zevergem</a> en <a href="/gemeente/zwijnaarde" class="url">Zwijnaarde</a>.</p> -->
   <p>Ontsluiting en bewaring van de heemkundige gegevens van de 9 dorpen die het werkgebied van de vereniging omvatten: Afsnee, De Pinte, Deurle, Eke, Nazareth, Sint-Denijs-Westrem, Sint-Martens-Latem, Zevergem en Zwijnaarde.</p>   
   <a class="mt-4 btn btn-secondary" href='over'>Over ons</a>
</section>

<div class="hr"></div>

<section class="container text-center">
   <h1>Recente updates</h1>

   <p *ngIf="!postEntries || !postEntries.length">Er zijn momenteel geen recente updates</p>
   
   <div *ngIf="postEntries" class="posts d-flex flex-wrap">
      <app-post *ngFor="let postEntry of postEntries" [postEntry]="postEntry" class="m-2"></app-post>
   </div>

   <!-- <a class="mt-4 btn btn-secondary" href="/">Alle updates</a> -->
</section>

<div class="hr"></div>

<section class="container text-center">
   <h1>Met steun van</h1>
   <div class="logos">
      <a target="_blank" href="https://depinte.be"><img src="../assets/media/sponsors/DePinte.jpg"></a>
      <a target="_blank" href="https://cultuurregioleieschelde.be"><img src="../assets/media/sponsors/Cultuurregio.jpg"></a>
      <a target="_blank" href="https://stad.gent"><img src="../assets/media/sponsors/GENT.jpg"></a>
      <a target="_blank" href="https://www.sint-martens-latem.be"><img src="../assets/media/sponsors/latem.jpg"></a>
      <a target="_blank" href="https://www.nazareth.be"><img src="../assets/media/sponsors/nazareth.jpg"></a>
      <a target="_blank" href="https://www.vlaanderen.be"><img src="../assets/media/sponsors/Vlaanderen.jpg"></a>
   </div>
   <p class="mt-4 text-muted">(Klik op een logo om de website te bezoeken)</p>
</section>