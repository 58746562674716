<nav>
   <div class="container d-flex align-items-center">
      <div class="logo">
         <img src="../assets/media/logo.png" alt="Heemkring Scheldeveld logo"> <a class="logo--name" href="">Heemkring Scheldeveld</a>
      </div>
      <span class="spacer"></span>
      <button *ngIf="!disabled" mat-icon-button (click)="drawer.toggle()">
         <mat-icon>menu</mat-icon>
      </button>
   </div>
</nav>
<div class="nav-spacer"></div>